<div class="c-card g--margin-t-15">
    <div class="c-card__body g--margin-b-30 row">      
        <div class="g--margin-t-30 col-12 col-lg-4 l-flex l-flex--direction-column l-flex--space-between">
            <!-- Grupo Observaciones de Barco -->
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <app-observaciones
                    #Grupo_Preferencia_Barco_Observaciones
                    [(textareaValue)] = "texto"
                    (textareaValueChange)="actualizarValorObservacion($event)"
                    ></app-observaciones>
                </div>
            </div>
        </div>
    </div>
</div>

