<div class="c-card g--margin-t-30 c-text c-text--gd">
  <div class="c-card__body g--margin-b-30 row">
    <mat-tab-group class="g--height-100">

      <!-- Mat-tab Alojamiento -->
      <mat-tab class="g--height-100"
        label="{{'precliente.formulario.hotel.titulo' | translate}}">
          <ng-template mat-tab-label>
            <span class="c-titulo c-titulo--md">{{'precliente.formulario.hotel.titulo' | translate}}</span>
          </ng-template>
          <app-preferencia-alojamiento
            [preferencias] = "preferencias"
            [Companias] = "convertirCompanias(TipoProducto.Hotel)"
            [clienteFicha] = "clienteFicha">
          </app-preferencia-alojamiento>
      </mat-tab>

      <!-- Mat-tab Aereo -->
      <mat-tab class="g--height-100"
        label="{{'precliente.formulario.vuelo.titulo' | translate}}">
          <ng-template mat-tab-label>
            <span class="c-titulo c-titulo--md">{{'precliente.formulario.vuelo.titulo' | translate}}</span>
          </ng-template>
          <app-preferencia-aereo
            [preferencias] = "preferencias"
            [AereoLineas] = "AereoLineas"
            [clienteFicha] = "clienteFicha">
          </app-preferencia-aereo>
      </mat-tab>

      <!-- Mat-tab Tren -->
      <mat-tab class="g--height-100"
        label="{{'precliente.formulario.tren.titulo' | translate}}">
          <ng-template mat-tab-label>
            <span class="c-titulo c-titulo--md">{{'precliente.formulario.tren.titulo' | translate}}</span>
          </ng-template>
          <app-preferencia-tren
            [preferencias] = "preferencias"
            [clienteFicha] = "clienteFicha"></app-preferencia-tren>
      </mat-tab>

      <!-- Mat-tab Rent a Car -->
      <mat-tab
        label="{{'precliente.formulario.rentacar.titulo' | translate}}">
          <ng-template mat-tab-label>
            <span class="c-titulo c-titulo--md">{{'precliente.formulario.rentacar.titulo' | translate}}</span>
          </ng-template>
          <app-preferencia-rent-a-car
            [preferencias] = "preferencias"
            [Companias] = "convertirCompanias(TipoProducto.RentAcar)"
            [CategoriasCoche] = "CategoriasCoche"
            [clienteFicha] = "clienteFicha"></app-preferencia-rent-a-car>
      </mat-tab>

      <!-- Mat-tab Tranfer -->
      <mat-tab
        label="{{'precliente.formulario.transfer.titulo' | translate}}">
          <ng-template mat-tab-label>
            <span class="c-titulo c-titulo--md">{{'precliente.formulario.transfer.titulo' | translate}}</span>
          </ng-template>
          <app-preferencias-transfer
            [preferencias] = "preferencias"
            [clienteFicha] = "clienteFicha"
          ></app-preferencias-transfer>
      </mat-tab>

      <!-- Mat-tab Barco -->
      <mat-tab
        label="{{'precliente.formulario.barco.titulo' | translate}}">
          <ng-template mat-tab-label>
            <span class="c-titulo c-titulo--md">{{'precliente.formulario.barco.titulo' | translate}}</span>
          </ng-template>
          <app-preferencias-barco
            [preferencias] = "preferencias"
            [clienteFicha] = "clienteFicha"
          ></app-preferencias-barco>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
