<div class="c-card g--margin-t-15">
    <div class="c-card__body g--margin-b-30 row">

        <!-- Grupo Preferencias Asientos Aereo -->
        <div class="g--margin-t-30 col-12 col-lg-3 g--margin-r-30 l-flex l-flex--direction-column l-flex--space-between">
            <app-grupo-preferencia
                #Grupo_Preferencia_Aereo_Preferente
                [required]="false"
                [TituloGrupo]="'precliente.formulario.vuelo.preferenciasenasientos'"
                [ListaPreferencias]="obtenerOpcionesPreferencia(preferencias,TipoPreferencia.PreferenciaAsientosVuelo)"
                [multiple] ="obtenerCamposMultiplesPreferencia(preferencias,TipoPreferencia.PreferenciaAsientosVuelo)"
                [required]="obtenerCamposObligatoriosPreferencia(preferencias,TipoPreferencia.PreferenciaAsientosVuelo)"
                posicionFlex="c-section__body l-flex g--margin-t-40 l-flex l-flex--direction-column"
                posicionMargin="l-flex g--margin-b-24">
                <div>
                    <label class="numero-asiento" for="numero-asiento">{{"precliente.formulario.vuelo.numero-fila" |translate}}</label>
                    <input type="number" maxlength="2" min="0" max="99"  [(ngModel)]="numero">
                </div>
            </app-grupo-preferencia>
            
        </div>
        
        <!-- Grupo Cadenas Aereas Preferentes -->
        <div class="g--margin-t-30 col-12 col-lg-4 g--margin-r-30">
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <b>{{"precliente.formulario.vuelo.cadenasaereaspreferentes" |translate}}</b>
                </div>
                <div class="c-section__body g--margin-t-40">
                <app-select-search-material
                    #Grupo_Preferencia_Aereo_Companias
                    [required]="false"
                    [buscador]="true"
                    [multiple]="true"
                    [opciones]="AereoLineas"
                    id="PaisExpedicion"
                    clase="form-control form-control-md"
                    claseIcono=""
                    label="{{'admin.comun.buscar' | translate }}"
                    placeholderBuscador="{{'admin.comun.buscar' | translate }}"
                    placeholderSelector="{{'admin.comun.buscar' | translate }}"
                >
                </app-select-search-material>
                </div>
            </div>
        </div>

        <!-- Grupo Observaciones Aereo -->
        <div class="g--margin-t-30 col-12 col-lg-4 l-flex l-flex--direction-column l-flex--space-between">
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <app-observaciones
                    #Grupo_Preferencia_Aereo_Observaciones
                    [(textareaValue)] = "texto"
                    (textareaValueChange)="actualizarValorObservacion($event)"
                    ></app-observaciones>
                </div>
            </div>
        </div>
       
    </div>
</div>
