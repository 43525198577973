<div class="c-body color-fondo" style="z-index: 1;">
    <div class=" c-panel l-flex l-flex--center">
        <div class="c-panel__body color-fondo g--width-100 g--height-100 g--margin-30">
            <div class="text-center">
                <div class="row">
                    <div class="col-10">

                        <!-- Titulo Administrador -->
                        <div class="c-titulo--xg">
                            {{'precliente.titulo.administrador' | translate}}
                        </div>
                    </div>

                    <!-- Boton Cerrar sesión -->
                    <div class="col-2 d-flex justify-content-end">
                        <div class="boton">
                            <app-boton clase="c-button c-button--dangerous c-text g--padding-12" [nombre]="'modal-sigue-activo.cerrar-sesion'" [usaIcono]="true"
                                claseIcono="pi pi-sign-out p-1 me-1" (click)="CierreSesion()"
                            >
                            </app-boton>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-panel__body color-fondo g--width-100 g--height-100 g--margin-30">
                <div class="c-section g--width-100 l-flex l-flex--direction-column">

                   

                    <div  class="g--margin-t-40 l-flex l-flex--wrap">

                        <!-- Nombre de las Empresas -->
                        <div class="g--margin-t-15">
                            <p-dropdown class="g--margin-r-15 g--margin-t-15"  [style]="{ width: '420px', height:'50px' }" [options]="empresas" [(ngModel)]="empresa"
                                optionLabel="Nombre" [filter]="true" filterBy="Nombre" [showClear]="true" (input)="listarEmpresasPreCliente($event)"
                                placeholder="{{'precliente.select.empresa'|translate}}">
                                <ng-template pTemplate="selectedItem">
                                    <div class="country-item country-item-value">
                                        <div>{{empresa?.Nombre}}</div>
                                    </div>  
                                    
                                </ng-template>
                                <ng-template let-empresa pTemplate="item">
                                    <div class="country-item">
                                        <div class="l-flex"><mat-icon
                                            class="g--margin-r-15 g--color-principal-11"></mat-icon>
                                            <div>{{empresa.Nombre}}</div>
                                        </div>
                                    </div>
                                </ng-template>  
                            </p-dropdown>
                            <div *ngIf="MensajeBuscarEmpresa" class="mensaje_validacion">
                                {{'precliente.formulario.validaciones.caracteres-minimo' | translate: {'valor': 5 } }}
                            </div>
                        </div>

                        <div  *ngIf="empresa?.Nombre.length > 0">
                            <div class="g--margin-r-30 g--width-900-px">

                                <!-- Boton Cargar en precliente -->
                                <app-boton (click)="cargarprecliente()"clase="c-button c-button--primary c-text g--margin-r-15 g--margin-t-15 g--padding-12"
                                [nombre]="'precliente.boton.cargarprecliente'" [usaIcono]="true"claseIcono="pi pi-database p-1 me-1">
                                </app-boton>

                                <!-- Boton Enviar a Destinux -->
                                <app-boton (click)="sincronizacion()" clase="c-button c-button--primary c-text g--margin-r-15 g--margin-t-15 g--padding-12"
                                [nombre]="'precliente.boton.enviar-Destinux'" [usaIcono]="true"claseIcono="pi pi-send p-1 me-1">
                                </app-boton>

                                <!-- Boton Cargar Tabla -->
                                <app-boton (click)="cargarTabla()" clase="c-button c-button--primary c-text g--margin-r-15 g--margin-t-15 g--padding-12"
                                [nombre]="'precliente.boton.cargatabla'" [usaIcono]="true" claseIcono="pi pi-sync p-1 me-1">
                                </app-boton>

                            </div>

                            <div  class="l-flex g--margin-t-15">

                                <!-- Filtrar por nombre del usuario -->
                                <div>
                                    <p-multiSelect  [style]="{ width: '220px', height:'50px' }"
                                        defaultLabel="{{'precliente.select.filtrarusuario'|translate}}" [options]="usuarios" optionLabel="Nombre"
                                        [(ngModel)]="usuarioSeleccionado" (onChange)="filtrarUsuarios()">
                                    </p-multiSelect>
                                </div>

                                <!-- filtrar por estado del usuario -->
                                <div class="g--margin-l-15">
                                    <p-multiSelect  [style]="{ width: '220px' , height:'50px' }" 
                                        defaultLabel="{{'precliente.select.filtrarestado'|translate}}" [options]="estadosFiltrados" optionLabel="estado" optionValue="num" [(ngModel)]="estadoSeleccionado" (onChange)="filtrarUsuarios()">
                                        <ng-template let-value>
                                            <div>
                                                <i class="pi pi-circle-fill  g--margin-r-15 {{colorEstado}}"></i>
                                                    {{"precliente.tabla.estado."+nombreEstado | translate}}
                                            </div>
                                        </ng-template>
                                        <ng-template let-estado pTemplate="item">
                                            <div>
                                                <i class="pi pi-circle-fill  g--margin-r-152 {{colorEstado(estado.num)}}"></i>
                                                    {{"precliente.tabla.estado."+estado.estado | translate}}
                                            </div>
                                        </ng-template>
                
                                    </p-multiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <app-tabla-usuarios *ngIf="vertabla" [diccionarios]="diccionarios"  [listaPaises] = "listaPaises" [listaPrefijos] = "listaPrefijos"
            [expresionesRegulares] = "expresionesRegulares" [listaComunidades] = "listaComunidades" [usuarios]="usuariosFiltrados" 
            [colorEstado] = "colorEstado" [estadosFiltrados]="estadosFiltrados" [nombreEstado] = "nombreEstado" [empresa]="empresa">
            </app-tabla-usuarios>  

        </div>
    </div>
</div>