<div class="{{sectionClass}}">
    <div class="l-flex g--margin-b-24" class="custom-radio-group" *ngFor="let opcion of obtenerOpcionesPreferencia">
        <p-checkbox  *ngIf="multiple " class="g--margin-r-15" name="{{opcion.ClaveTraduccion | translate}}"  [(ngModel)]="opcionesSeleccionadas" [value]='opcion.Id.toString()' (onChange)="CheckboxCambio($event)">
        </p-checkbox>
        
        
        <p-radioButton *ngIf="!multiple" name="{{opcion.ClaveTraduccion | translate}}"   [(ngModel)]="opcionesSeleccionadas" [value]='opcion.Id.toString()' (onClick)="radioButtonCambio(opcion.Id.toString(), $event)"></p-radioButton>
        <div class="">
            {{opcion.ClaveTraduccion | translate}}
        </div>
    </div>
</div>