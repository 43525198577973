import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OTRO } from 'src/app/models/constantes';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { ICompania, IDiccionarios, IItem, IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { ItemConverter } from 'src/app/shared/item-converter';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';
import { SelectSearchMaterialComponent } from '../../../form-componentes/material-framework/select-search-material/select-search-material.component';
import { preferencias } from 'src/app/models/General/preferencias';
import { ObservacionesComponent } from '../../../form-componentes/observaciones/observaciones.component';

@Component({
  selector: 'app-preferencia-alojamiento',
  templateUrl: './preferencia-alojamiento.component.html',
  styleUrls: ['./preferencia-alojamiento.component.scss']
})
export class PreferenciaAlojamientoComponent extends preferencias implements OnInit {

  @ViewChild('Grupo_Preferencia_Alojamiento_Imprescindibles') Grupo_Preferencia_Alojamiento_Imprescindibles: GrupoPreferenciaComponent;
  @ViewChild('Grupo_Preferencia_Alojamiento_Cadenas') Grupo_Preferencia_Alojamiento_Cadenas: SelectSearchMaterialComponent;
  @ViewChild('Grupo_Preferencia_Alojamiento_Cadenas_Observacion') Grupo_Preferencia_Alojamiento_Cadenas_Observacion: ObservacionesComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  isOtro: boolean = false;
  TipoPreferencia = ETipoPreferencia;

  @Input() preferencias: IPreferencia[];
  @Input() Companias: IItem[];

  @Input() clienteFicha: IFichaCliente;



  //En caso alojamiento tener la opcion de elegir otro
  seleccionarOpcionOtroAlojamiento(opciones) {
    if (opciones.find(a => a.toString() == OTRO)) {
      this.isOtro = true
    } else {
      this.isOtro = false
    }
  }

  //Obtener la lista de las preferencias alojamiento guardadas
  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let tuplaAlojamientoImprescindibles = this.Grupo_Preferencia_Alojamiento_Imprescindibles.obtenerPreferencias()
    let imprescindiblesAlojamiento: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.ImprescindiblesHotel,
      Preferencias: tuplaAlojamientoImprescindibles[0],
      InfoEspecifica: tuplaAlojamientoImprescindibles[1]
    }
    ListaPreferencias.push(imprescindiblesAlojamiento)


    let CadenasAlojamiento: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.CadenasHoteleras,
      Preferencias: this.Grupo_Preferencia_Alojamiento_Cadenas.obtenerPreferencias(),
      InfoEspecifica: this.obtenerObservacion()
    }
    ListaPreferencias.push(CadenasAlojamiento)


    return ListaPreferencias;

  }

  //En caso de seleccionar Otro tendremos una opción de observaciones
  obtenerObservacion(): string | null {
    let observacion: string | null = null;
    if (this.texto !== null && this.texto.length > 0) {
      observacion = this.texto;
    }
    return observacion;
  }

  //Actualziar preferencias Alojamiento
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias
    let preferenciaImprescindiblesHotel: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.ImprescindiblesHotel)
    let preferenciaCadenasHotel: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.CadenasHoteleras)

    if (preferenciaCadenasHotel?.Preferencias.find(a => a.toString() == OTRO)) {
      this.isOtro = true;
      this.Grupo_Preferencia_Alojamiento_Cadenas_Observacion.actualizar(preferenciaCadenasHotel?.InfoEspecifica)
    }

    this.Grupo_Preferencia_Alojamiento_Imprescindibles.actualizar(preferenciaImprescindiblesHotel?.Preferencias);
    this.Grupo_Preferencia_Alojamiento_Cadenas.actualizarSeleccion(preferenciaCadenasHotel?.Preferencias);

  }

  //Validar preferencias Alojamiento
  validarPreferencias() {
    let estadoAlojamientoImprescindibles = this.Grupo_Preferencia_Alojamiento_Imprescindibles.validarPreferencias()
    let estadoCadenasAlojamiento = this.Grupo_Preferencia_Alojamiento_Cadenas.validarPreferencias()
    if (estadoAlojamientoImprescindibles && estadoCadenasAlojamiento) {
      return true;
    }
    return false;
  }



}
