import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia, ETipoProducto } from 'src/app/models/enums.enum';
import { ICompania, IDiccionarios, IItem, IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { ItemConverter } from 'src/app/shared/item-converter';
import { PreferenciaAlojamientoComponent } from '../preferencia-alojamiento/preferencia-alojamiento.component';
import { PreferenciaAereoComponent } from '../preferencia-aereo/preferencia-aereo.component';
import { PreferenciaTrenComponent } from '../preferencia-tren/preferencia-tren.component';
import { PreferenciaRentACarComponent } from '../preferencia-rent-a-car/preferencia-rent-a-car.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { preferencias } from 'src/app/models/General/preferencias';
import { PreferenciasTransferComponent } from '../preferencias-transfer/preferencias-transfer.component';
import { PreferenciasBarcoComponent } from '../preferencias-barco/preferencias-barco.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-usuario-preferencias',
  templateUrl: './usuario-preferencias.component.html',
  styleUrls: ['./usuario-preferencias.component.scss']
})
export class UsuarioPreferenciasComponent extends preferencias implements OnInit {

  @ViewChild(PreferenciaAlojamientoComponent) Seccion_Alojamiento: PreferenciaAlojamientoComponent;
  @ViewChild(PreferenciaAereoComponent) Seccion_Aereo: PreferenciaAereoComponent;
  @ViewChild(PreferenciaTrenComponent) Seccion_Tren: PreferenciaTrenComponent;
  @ViewChild(PreferenciaRentACarComponent) Seccion_RentACar: PreferenciaRentACarComponent;
  @ViewChild(PreferenciasTransferComponent) Seccion_Transfer: PreferenciasTransferComponent;
  @ViewChild(PreferenciasBarcoComponent) Seccion_Barco: PreferenciasBarcoComponent;

  constructor(private _translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
  }

  OTRO: string = '-99'

  listaCompaniasAlojamiento: IItem[] = [];
  listaCompaniasRentACar: IItem[] = [];

  companiasConvertidos: boolean = false;
  TipoPreferencia = ETipoPreferencia;
  TipoProducto = ETipoProducto;

  @Input() AereoLineas: IItem[];
  @Input() Companias: ICompania[];
  @Input() CategoriasCoche: IItem[];

  @Input() preferencias: IPreferencia[];

  @Input() clienteFicha: IFichaCliente;






  convertirCompanias(TipoProducto: ETipoProducto) {
    if (this.Companias && this.companiasConvertidos === false) {

      this.listaCompaniasAlojamiento = ItemConverter.transformarObjetoToItem(this.Companias.filter(c => c.TipoProducto == ETipoProducto.Hotel), 'Codigo', 'Nombre');
      this.listaCompaniasRentACar = ItemConverter.transformarObjetoToItem(this.Companias.filter(c => c.TipoProducto == ETipoProducto.RentAcar), 'Codigo', 'Nombre');
      let Clave: string = this.OTRO;
      let Valor: string = this._translate.instant('precliente.otro');

      this.listaCompaniasAlojamiento.unshift({ Clave, Valor });
      this.companiasConvertidos = true;
    }

    if (TipoProducto == ETipoProducto.Hotel) {
      return this.listaCompaniasAlojamiento;
    }
    return this.listaCompaniasRentACar

  }

  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    ListaPreferencias = [...ListaPreferencias, ...this.Seccion_Alojamiento.obtenerPreferenciasGuardadas()];
    ListaPreferencias = [...ListaPreferencias, ...this.Seccion_Tren.obtenerPreferenciasGuardadas()];
    ListaPreferencias = [...ListaPreferencias, ...this.Seccion_Aereo.obtenerPreferenciasGuardadas()];
    ListaPreferencias = [...ListaPreferencias, ...this.Seccion_RentACar.obtenerPreferenciasGuardadas()];
    ListaPreferencias = [...ListaPreferencias, ...this.Seccion_Transfer.obtenerPreferenciasGuardadas()];
    ListaPreferencias = [...ListaPreferencias, ...this.Seccion_Barco.obtenerPreferenciasGuardadas()];

    return ListaPreferencias;


  }


  actualizar() {
    this.Seccion_Alojamiento.actualizar()
    this.Seccion_Aereo.actualizar()
    this.Seccion_Tren.actualizar()
    this.Seccion_RentACar.actualizar()
    this.Seccion_Transfer.actualizar()
    this.Seccion_Barco.actualizar()
  }

  validarPreferencias() {
    let Alojamiento = this.Seccion_Alojamiento.validarPreferencias()
    let Aereo = this.Seccion_Aereo.validarPreferencias()
    let Tren = this.Seccion_Tren.validarPreferencias()
    let RentACar = this.Seccion_RentACar.validarPreferencias()

    if (Alojamiento && Aereo && Tren && RentACar) {
      return true;
    }
    return false;
  }



}
