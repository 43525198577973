import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { ICompania, IItem, IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { ItemConverter } from 'src/app/shared/item-converter';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { ObservacionesComponent } from '../../../form-componentes/observaciones/observaciones.component';
import { SelectSearchMaterialComponent } from '../../../form-componentes/material-framework/select-search-material/select-search-material.component';
import { preferencias } from 'src/app/models/General/preferencias';

@Component({
  selector: 'app-preferencia-rent-a-car',
  templateUrl: './preferencia-rent-a-car.component.html',
  styleUrls: ['./preferencia-rent-a-car.component.scss']
})
export class PreferenciaRentACarComponent extends preferencias implements OnInit {

  @ViewChild('Grupo_Preferencia_RentACar_CompaniasAlquiler') Grupo_Preferencia_RentACar_CompaniasAlquiler: SelectSearchMaterialComponent;
  @ViewChild('Grupo_Preferencia_RentACar_Observaciones') Grupo_Preferencia_RentACar_Observaciones: ObservacionesComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  TipoPreferencia = ETipoPreferencia;

  @Input() preferencias: IPreferencia[];

  @Input() Companias: IItem[];

  @Input() CategoriasCoche: IItem[];

  @Input() clienteFicha: IFichaCliente;




  contador: string = '0/100';

  //Actualizar contador para mostrar el número de letras ingresado hasta llegar al máximo
  actualizarContador(texto: string): void {
    const longitudMax: string | null = '100';
    const longitudAct: number = texto.length;
    this.contador = `${longitudAct}/${longitudMax}`;
  }

  //Obtener la lista de las preferencias rentACar guardadas
  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let RentAcarCompaniasAlquiler = this.Grupo_Preferencia_RentACar_CompaniasAlquiler.obtenerPreferencias()
    let CompaniasAlquilerRentACar: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.CompaniasCoche,
      Preferencias: RentAcarCompaniasAlquiler,
      InfoEspecifica: null
    }
    ListaPreferencias.push(CompaniasAlquilerRentACar)

    let textoObservacion = this.Grupo_Preferencia_RentACar_Observaciones.obtenerPreferencias()
    let preferenciaRentACarObservaciones: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.ObservacionRentACar,
      Preferencias: null,
      InfoEspecifica: textoObservacion,
    }
    ListaPreferencias.push(preferenciaRentACarObservaciones)

    return ListaPreferencias;
  }

  //Actualziar preferencias rentACar
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias
    let preferenciaCompaniasCoche: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.CompaniasCoche)
    let preferenciaTextoObservacionVehiculo: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.ObservacionRentACar)

    this.Grupo_Preferencia_RentACar_CompaniasAlquiler.actualizarSeleccion(preferenciaCompaniasCoche?.Preferencias);
    this.Grupo_Preferencia_RentACar_Observaciones.actualizar(preferenciaTextoObservacionVehiculo?.InfoEspecifica)
  }

  //Actualziar preferencias rentACar
  validarPreferencias() {
    let estadoRentAcarCompaniasAlquiler = this.Grupo_Preferencia_RentACar_CompaniasAlquiler.validarPreferencias()

    if (estadoRentAcarCompaniasAlquiler) {
      return true;
    }
    return false;
  }

}




