
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { ObservacionesComponent } from '../../../form-componentes/observaciones/observaciones.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { preferencias } from 'src/app/models/General/preferencias';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';
@Component({
  selector: 'app-preferencias-barco',
  templateUrl: './preferencias-barco.component.html',
  styleUrls: ['./preferencias-barco.component.scss']
})
export class PreferenciasBarcoComponent extends preferencias implements OnInit {

  @ViewChild('Grupo_Preferencia_Barco_Observaciones') Grupo_Preferencia_Barco_Observaciones: ObservacionesComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  TipoPreferencia = ETipoPreferencia;

  @Input() preferencias: IPreferencia[];

  @Input() clienteFicha: IFichaCliente;


  //Obtener la lista de las preferencias barco guardadas
  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let textoObservacion = this.Grupo_Preferencia_Barco_Observaciones.obtenerPreferencias()
    let preferenciaBarcoObservaciones: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.ObservacionBarco,
      Preferencias: null,
      InfoEspecifica: textoObservacion,
    }
    ListaPreferencias.push(preferenciaBarcoObservaciones)

    return ListaPreferencias;

  }

  //Actualziar preferencias barco
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias

    let preferenciaTextoObservacionBarco: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.ObservacionBarco)

    this.Grupo_Preferencia_Barco_Observaciones.actualizar(preferenciaTextoObservacionBarco?.InfoEspecifica);
  }


}
