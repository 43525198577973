import { Pipe, PipeTransform } from '@angular/core';
import { Util } from './util';
import { IItem } from '../models/interfaces-apis/diccionarios';

@Pipe({
  name: 'filtro'
})

/**
 * Clase que permite realizar el filtro de las opciones de selector
 */
export class FiltroSelectPipe implements PipeTransform
{

  /**
   * Método que permite realizar la busqueda de un texto en la lista de opciones
   * @param opciones Lista de opciones
   * @param textoBusqueda Texto a buscar en las opciones
   * @returns Lista filtrada
   */
  transform(opciones: IItem[], textoBusqueda: string): IItem[] {
    if(!opciones || !opciones.length) return opciones;
    if(!textoBusqueda || !textoBusqueda.length) return opciones;
    let resultados = []
    const regex = /^\d+$/;
    if(regex.test(textoBusqueda) ){
       resultados = opciones.filter(item =>
        Util.normalizarTexto(item.Valor).split('+')[1].trim().startsWith(Util.normalizarTexto(textoBusqueda.toLowerCase()))
      )

    }else{
       resultados = opciones.filter(item =>
        Util.normalizarTexto(item.Valor).toLowerCase().includes(Util.normalizarTexto(textoBusqueda.toLowerCase()))
      );
      if (resultados.length > 0) {
        if (resultados.length > 10) {
          resultados = opciones.filter(item =>
            Util.normalizarTexto(item.Valor).toLowerCase().split(', ').some(pais => pais.startsWith(Util.normalizarTexto(textoBusqueda.toLowerCase())))
          );
      
          // Si no se encuentran resultados con startsWith, aplicamos includes sin vaciar la lista
          if (resultados.length === 0) {
            resultados = opciones.filter(item =>
              Util.normalizarTexto(item.Valor).toLowerCase().includes(Util.normalizarTexto(textoBusqueda.toLowerCase()))
            );
          }
        }
        
      }
    }
    return resultados;
  }

}
