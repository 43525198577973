<div class="scroll">
<mat-form-field appearance="outline" class="input-group" class="custom-outline" [ngClass]="widthClass">
  <mat-label class="c-text c-text--gd">{{label}}</mat-label>

  <span matPrefix>
    <i [class]="claseIcono"></i>
  </span>
  
    <mat-select (openedChange)="focoEnBuscador($event); vaciaTextoBusqueda($event)" class="c-input__select"  [id]="id" [placeholder]="placeholderBuscador" [multiple]="multiple" [(value)]="seleccionada" [disabled]="readonly"   [required]="isRequired" [formControl]="control">
  
    <div class="caja-busqueda w-100 p-2" *ngIf="buscador" >
      <div class="d-inline-flex caja-input w-100 m-1 p-1">
        <input #focoBuscador  class="c-text c-text--gd" matInput [placeholder]="placeholderBuscador" type="text" [ngClass]="clase"  [(ngModel)]="textoBusqueda">
        <span matPrefix> <i class="fa-solid fa-magnifying-glass ms-2"></i> </span>
      </div>
    </div>
    
    <mat-option *ngFor="let opcion of opciones | filtro : textoBusqueda"
      [value]="opcion.Clave"
      [disabled]="opcion.Clave === null"
      (click)="opcionSeleccionada(opcion.Clave)"
    >

      <ng-container *ngIf="opcion.Clave !== null; else sinResultado" >
        {{ opcion.Valor | translate }}
      </ng-container>

      <ng-template #sinResultado>
        {{ opcion.Valor | translate }}
      </ng-template>

    </mat-option>

  </mat-select>


</mat-form-field>
</div>